angular.module('zippgo.controllers').controller('QuoteController', ['ApiService', '$window', function(apiService, $window) {
	var ctrlr = this;

	this.submittingQuote = false;
	this.minDate = new Date();
	this.minDate.setDate(this.minDate.getDate() + 1);
	this.maxDate = new Date();
	this.maxDate.setMonth(this.maxDate.getMonth() + 6);
	this.defaultDate = this.minDate;
	this.quoteForm = false;

	this.dropdowns = {
		locations: $window.locationOptions,
	}

	this.quote = {
		location: '',
		date: '',
		name: '',
		email: '',
		phone: '',
	}

	this.submitQuote = function() {
		if (this.quoteForm.$invalid)
			return;

		this.submittingQuote = true;
		this.quoteSubmitted = false;

		apiService.submitQuote(this.quote.name, this.quote.email, this.quote.phone, this.quote.location, new Date(this.quote.date)).then(function() {
			ctrlr.submittingQuote = false;
			//ctrlr.quoteSubmitted = true;
			$window.location = '/quote-submitted';
		}, function(err) {
			console.log(err);
		});
	}

}]);