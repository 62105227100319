angular.module('zippgo.controllers')
    .controller('OrderController', ['ApiService', '$scope', '$timeout', '$location', '$window', '$filter', 'orderByFilter',
     function (apiService, $scope, $timeout, $location, $window, $filter, orderBy) {
        var ctrlr = this;

        this.submittingOrder = false;
        this.minDate = new Date();
        this.minDate.setDate(this.minDate.getDate() + 1);
        this.maxDate = new Date();
        this.maxDate.setMonth(this.maxDate.getMonth() + 6);
        this.defaultDate = this.minDate;

        this.scrollContainer = angular.element('#content');
        this.bodyContainer = angular.element('body');
        this.basketOpen = false;

        var navigator = $window.navigator;

        if (navigator.userAgent && (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i))) {
            this.isTouch = true;
        }

        this.smoothScrollOptions = {
            duration: 800,
            offset: 200,
        }

        this.forms = {
            personalDetails: undefined,
            paymentDetails: undefined,
            dropOff: undefined,
            pickUp: undefined
        };

        this.scrollSections = {
            top: angular.element('#scroll-top'),
            /*packages: document.getElementById('packages'),
             packingSupplies: document.getElementById('packing-supplies'),
             movingSupplies: document.getElementById('moving-supplies'),
             dropOff: document.getElementById('drop-off'),
             pickUp: document.getElementById('pick-up'),
             personalDetails: document.getElementById('personal-details'),
             paymentDetails: document.getElementById('payment-details'),*/
        };

        this.dropdowns = {
            rental_weeks: [],

            access: {
                dropoff: [],
                pickup: []
            },

            how_find: $window.findOptions,

            months: [{
                text: '1 - January',
                value: 0
            }, {
                text: '2 - February',
                value: 1
            }, {
                text: '3 - March',
                value: 2
            }, {
                text: '4 - April',
                value: 3
            }, {
                text: '5 - May',
                value: 4
            }, {
                text: '6 - June',
                value: 5
            }, {
                text: '7 - July',
                value: 6
            }, {
                text: '8 - August',
                value: 7
            }, {
                text: '9 - September',
                value: 8
            }, {
                text: '10 - October',
                value: 9
            }, {
                text: '11 - November',
                value: 10
            }, {
                text: '12 - December',
                value: 11
            }],

            years: []
        }

        var currentDate = new Date();

        this.data = false;
        this.loading = true;

        this.checkingDropOff = false;
        this.checkingPickUp = false;
        this.uncheckedDropOff = true;
        this.uncheckedPickUp = true;
        this.checkDropOffTimeout = undefined;
        this.checkPickUpTimeout = undefined;

        this.pickUpNoZip = false;
        this.dropOffNoZip = false;

        this.uncheckedpCode = false;
        this.checkingDiscountCode = false;
        this.checkDiscountCodeTimeout = false;

        this.packageValidForRegion = true;

        this.cutOffDate = new Date() + 1;

        this.loadingTimeSlots = false;


        for (var i = 0; i < 10; i++) {
            this.dropdowns.years.push({
                text: currentDate.getFullYear() + i,
                value: currentDate.getFullYear() + i
            });
        }

        this.getPSTTimeNow = function () {
            var pstOffset = -7;

            var nowDate = new Date();
            var offset = nowDate.getTimezoneOffset() / 60;
            var hours = nowDate.getHours();

            var utcHours = hours + offset;

            var pstHours = utcHours + pstOffset;

            return pstHours;
        }

        this.isExpirationValid = function (finalCheck) {
            if (finalCheck && (this.basket.payment.expiration.month.value < 0 || this.basket.payment.expiration.year.value < 1))
                return false;

            if (this.basket.payment.expiration.month.value == -1 || this.basket.payment.expiration.year.value == 0)
                return true; // It's not done yet.

            if (this.basket.payment.expiration.year.value < currentDate.getFullYear())
                return false;

            if (this.basket.payment.expiration.year.value == currentDate.getFullYear() && this.basket.payment.expiration.month.value < currentDate.getMonth())
                return false;

            return true;
        }

        this.buildAccessOptions = function () {
	    this.dropdowns.access.dropoff = [{
                text: 'Yes, there are 1-2 flights of stairs (+$' + $filter('currency')(ctrlr.data.settings.stairs_access_fee * ctrlr.basket.box_count / 100, '') + ')',
                value: 'stairs',
            }, {
                text: 'Yes, there are more than 2 flights of stairs (Call for price)',
                value: 'stairs_2_flights',
            }, {
                text: 'Yes, I have an elevator at street level (+$' + $filter('currency')(ctrlr.data.settings.elevator_access_fee * ctrlr.basket.box_count / 100, '') + ')',
                value: 'elevator',
            }, {
                text: 'Yes, but I will meet you at street level (Free)',
                value: 'meet_at_street',
            }, {
                text: 'No, delivery is street level (Free)',
                value: 'street',
            }];

            this.dropdowns.access.pickup = angular.copy(this.dropdowns.access.dropoff);

            this.dropdowns.access.pickup[2].text.replace('Delivery', 'Pickup');

            if (this.basket.dropoff.access.value == 'stairs') {
                this.basket.dropoff.access = this.dropdowns.access.dropoff[0];
            }

            if (this.basket.dropoff.access.value == 'elevator') {
                this.basket.dropoff.access = this.dropdowns.access.dropoff[2];
            }

            if (this.basket.pickup.access.value == 'stairs') {
                this.basket.pickup.access = this.dropdowns.access.pickup[0];
            }

            if (this.basket.pickup.access.value == 'elevator') {
                this.basket.pickup.access = this.dropdowns.access.pickup[2];
            }
        }

        this.generateSliderOpts = function () {
            ctrlr.rentalSliderOpts = {
                floor: 1,
                ceil: ctrlr.data.settings.max_weeks,
                hideLimitLabels: true,
                showTicksValues: true,
                showSelectionBar: true,

                translate: function (value) {
                    if (value == 1) {
                        return '1 Week';
                    } else {
                        return value + " Weeks";
                    }
                },
                onChange: function (value) {
                    console.log("Change test");
                    ctrlr.getTimeSlots();
                }
            }
        }

        this.regions = {
            pickup: {},
            dropoff: {}
        };

        this.time_slots = {
            dropoff: [{
                slot_description: 'Select a delivery date first',
                divider: true,
                id: 0,
            }],
            pickup: [{
                slot_description: 'Select a delivery date first',
                divider: true,
                id: 0,
            }]
        }

        this.cardTypes = ['Visa', 'Discovery', 'American Express', 'MasterCard'];

        this.autoComplete = {
            options: {
                componentRestrictions: {
                    country: 'us'
                },
                types: ['address']
            }
        };

        this.basket = {
            package: false,
            moving_supplies: [],
            packing_supplies: [],
            dropoff: {
                date: '',
                dateObj: false,
                times: {
                    firstObj: {
                        slot_description: 'Select a time slot',
                        id: 0,
                    },
                    firstNative: '0',
                    secondObj: {
                        slot_description: 'Select a time slot',
                        id: 0,
                    },
                    secondNative: '0',
                },
                firstPristine: true,
                secondPristine: true,
                address: false,
                apt_no: '',
                notes: '',
                access: {
                    text: 'Select an option',
                    value: '',
                },
                nativeAccess: '',
                fee: 0,
                valid: false
            },
            pickup: {
                times: {
                    firstObj: {
                        slot_description: 'Select a time slot',
                        id: 0,
                    },
                    firstNative: '0',
                    secondObj: {
                        slot_description: 'Select a time slot',
                        id: 0,
                    },
                    secondNative: '0',
                },
                firstPristine: true,
                secondPristine: true,
                address: false,
                apt_no: '',
                notes: '',
                access: {
                    text: 'Select an option',
                    value: '',
                },
                nativeAccess: '',
                fee: 0,
                valid: false
            },
            rental_weeks: {
                text: '2 Weeks',
                value: 2
            },
            slider_rental_weeks: 2,
            personal: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                secondary_phone: '',
                company: '',
                how_find: {
                    text: 'Select an option',
                    value: ''
                },
                howFindNative: '',
                need_moving: false
            },
            discount_code: '',
            discount: 0,
            discount_type: false,

            tax: 0,
            total: 0,
            box_count: 0,

            payment: {
                name: '',
                number: undefined,
                expiration: {
                    month: {
                        text: 'Month',
                        value: -1,
                    },
                    monthNative: -1,
                    year: {
                        text: 'Year',
                        value: 0,
                    },
                    yearNative: -1,
                },
                cvv: '',
            }
        };

        this.getData = function () {
            apiService.getProductData(ctrlr.regions.dropoff.id, ctrlr.regions.pickup.id).then(function (response) {
                ctrlr.data = response.data;
                ctrlr.basket.dropoff.delivery_fee = ctrlr.data.dropoff.delivery_fee;
                ctrlr.basket.pickup.delivery_fee = ctrlr.data.pickup.delivery_fee;

                ctrlr.maxDate = new Date(ctrlr.data.dates.max);
                ctrlr.minDate = new Date(ctrlr.data.dates.min);

                ctrlr.regions.dropoff = response.data.dropoff;
                ctrlr.regions.pickup = response.data.pickup;

                ctrlr.generateRentalWeeks();
                ctrlr.updateMinOrder();

                ctrlr.loading = false;
            }, function (response) {
                ctrlr.loading = false;
                ctrlr.error = false;
                //alert(response.data);
            });
        };

        this.handleCloseCustomModal = function () {
            if (ctrlr.data.settings.custom_min_order > ctrlr.basket.package.custom_price) {
                ctrlr.basket.package = false;
                ctrlr.stage = 1;
            }
        }

        this.generateRentalWeeks = function () {
            ctrlr.generateSliderOpts();
            ctrlr.dropdowns.rental_weeks = [];

            for (var i = 0; i < ctrlr.data.settings.max_weeks; i++) {
                ctrlr.dropdowns.rental_weeks.push({
                    text: (i + 1) + ' Week' + (i > 0 ? 's' : ''),
                    value: i + 1,
                });
            }
        }

        this.updateMinOrder = function () {
            ctrlr.min_order = 0;
            /*if (ctrlr.data.dropoff.min_order > ctrlr.data.pickup.min_order) {
             ctrlr.min_order = ctrlr.data.dropoff.min_order;
             } else {
             ctrlr.min_order = ctrlr.data.pickup.min_order;
             }*/
        }

        this.onRentalWeeksChange = function () {

            if (this.dropdowns.rental_weeks.length > 0) {
                this.basket.rental_weeks = this.dropdowns.rental_weeks[this.basket.slider_rental_weeks - 1];
                this.getTimeSlots();
            }
        }

        this.getTimeSlots = function (isFromRentalChange, selected, pickupOnly) {

            if (selected) {
                this.basket.rental_weeks = selected;
            }

            if (!this.basket.dropoff.date || this.basket.dropoff.date == '') {
                if (!this.basket.dropoff.dateObj) {
                    return;
                } else {
                    this.basket.dropoff.date = $filter('date')(this.basket.dropoff.dateObj, 'fullDate');
                }
            }

            this.time_slots = {
                dropoff: false,
                pickup: false
            };

            ctrlr.basket.pickup.times = {
                firstObj: {
                    slot_description: 'Select a time slot',
                    id: 0,
                },
                firstNative: 0,
                secondObj: {
                    slot_description: 'Select a time slot',
                    id: 0,
                },
                secondNative: 0,
            };

            if (!isFromRentalChange && !pickupOnly) {
                ctrlr.basket.dropoff.times = {
                    firstObj: {
                        slot_description: 'Select a time slot',
                        id: 0,
                    },
                    firstNative: 0,
                    secondObj: {
                        slot_description: 'Select a time slot',
                        id: 0,
                    },
                    secondNative: 0,
                };

                ctrlr.basket.dropoff.dateObj = new Date(ctrlr.basket.dropoff.date);
            }

            var nowDate = new Date();

            var hours = ctrlr.getPSTTimeNow();
            if (hours > 18 && nowDate.getFullYear() == this.basket.dropoff.dateObj.getFullYear() && nowDate.getMonth() == this.basket.dropoff.dateObj.getMonth() && (parseInt(nowDate.getDate()) + 1) == this.basket.dropoff.dateObj.getDate()) {
                this.forms.dropOff.dropOffDate.$setValidity('next-day', false);
                this.forms.dropOff.nativeDropOffDate.$setValidity('next-day', false);
                this.forms.dropOff.dropOffDate.$setValidity('too-soon', true);
                this.forms.dropOff.nativeDropOffDate.$setValidity('too-soon', true);
                console.log(this.forms.dropOff.nativeDropOffDate.$error['next-day']);
            }
            else if (nowDate < this.basket.dropoff.dateObj) {

                this.forms.dropOff.dropOffDate.$setValidity('next-day', true);
                this.forms.dropOff.nativeDropOffDate.$setValidity('next-day', true);
                this.forms.dropOff.dropOffDate.$setValidity('too-soon', true);
                this.forms.dropOff.nativeDropOffDate.$setValidity('too-soon', true);

                ctrlr.loadingTimeSlots = true;

                apiService.getTimeSlots(this.regions.dropoff.id, this.regions.pickup.id, this.basket.dropoff.date, this.basket.rental_weeks.value).then(function (response) {
                    ctrlr.time_slot_data = response.data;
                    ctrlr.refreshTimeSlotDropdowns('dropoff');
                    ctrlr.refreshTimeSlotDropdowns('pickup');
                    ctrlr.loadingTimeSlots = false;

                    ctrlr.basket.pickup.date = new Date($filter('isoDate')(response.data.pickupDate));

                    if (ctrlr.time_slots.pickup.length == 0) {
                        ctrlr.time_slots.pickup.push({
                            slot_description: 'No slots available for selected date',
                            value: '',
                        });

                        ctrlr.basket.pickup.firstObj = ctrlr.basket.pickup.secondObj = {
                            slot_description: 'No slots available for selected date',
                            value: '',
                        }
                    }

                    if (ctrlr.time_slots.dropoff.length == 0) {
                        ctrlr.time_slots.dropoff.push({
                            slot_description: 'No slots available for selected date',
                            value: '',
                        });

                        ctrlr.basket.dropoff.firstObj = ctrlr.basket.dropoff.secondObj = {
                            slot_description: 'No slots available for selected date',
                            value: '',
                        }
                    }

                }, function (err) {
                    ctrlr.loadingTimeSlots = false;

                    if (err.data.message) {
                        swal({
                            title: err.data.message.title,
                            text: err.data.message.text,
                            type: 'error',
                            showCancelButton: false,
                            confirmButtonText: 'OK',
                            html: true,
                        });
                    } else {
                        alert(err);
                    }
                });
            } else {
                this.forms.dropOff.dropOffDate.$setValidity('next-day', true);
                this.forms.dropOff.nativeDropOffDate.$setValidity('next-day', true);
                this.forms.dropOff.dropOffDate.$setValidity('too-soon', false);
                this.forms.dropOff.nativeDropOffDate.$setValidity('too-soon', false);
            }
        }

        this.refreshTimeSlotDropdowns = function (type) {
            ctrlr.time_slots[type] = {
                first: [],
                second: []
            };

            angular.forEach(ctrlr.time_slot_data[type], function (slot, ind, arr) {
                if (!ctrlr.basket[type].times.firstObj | ctrlr.basket[type].times.firstObj.id != slot.id) {
                    ctrlr.time_slots[type].second.push(slot);
                }

                if (!ctrlr.basket[type].times.secondObj || ctrlr.basket[type].times.secondObj.id != slot.id)
                    ctrlr.time_slots[type].first.push(slot);
            });
        }

        this.onPackageQuantityChange = function (product) {
            if (product.pivot.custom_quantity > product.pivot.original_quantity + product.pivot.customisation_limit) {
                product.pivot.custom_quantity = product.pivot.original_quantity + product.pivot.customisation_limit;
            }

            this.recalculatePackage();
        }

        this.onPackageQuantityBlur = function (product) {
            if (product.pivot.custom_quantity == '' || product.pivot.custom_quantity == null) {
                product.pivot.custom_quantity = 0;
            }

            if (product.pivot.custom_quantity < product.pivot.original_quantity)
                product.pivot.custom_quantity = product.pivot.original_quantity;

            this.recalculatePackage();
        }

        this.selectPackage = function (package) {
            this.basket.package = package;
            this.recalculate();

            if (!ctrlr.packageValidForRegion)
                ctrlr.checkPackageInRegions();

            this.checkCompanyValidity(ctrlr.basket.personal.company);

            if (package.customisable)
                this.customisePackage();
            else
                this.scrollToBottom();
        }

        this.customisePackage = function () {
            this.recalculatePackage();

            if (!this.basket.package.custom_price)
                this.basket.package.custom_price = this.basket.package.weekly_pricing[this.basket.rental_weeks.value - 1];

            if (!this.basket.package.original_price)
                this.basket.package.original_price = this.basket.package.weekly_pricing[this.basket.rental_weeks.value - 1];

            angular.forEach(this.basket.package.products, function (product, foo, bar) {
                if (product.pivot.customisable == 0)
                    return;

                product.pivot.custom_quantity = product.pivot.quantity;

                if (product.pivot.original_quantity === undefined)
                    product.pivot.original_quantity = product.pivot.quantity;
            });
        }

        this.saveCustomPackage = function () {
            if (ctrlr.basket.package.custom_price < ctrlr.data.settings.custom_min_order)
                return;

            angular.forEach(this.basket.package.products, function (product, foo, bar) {
                product.pivot.quantity = product.pivot.custom_quantity;
            });

            ctrlr.basket.package.price = ctrlr.basket.package.custom_price;

            if (ctrlr.stage == 1) {
                ctrlr.scrollToBottom();
            }

            this.recalculate();
        }

        this.recalculatePackage = function () {
            this.basket.package.custom_price = this.basket.package.weekly_pricing[this.basket.rental_weeks.value - 1];
            this.basket.package.box_count = 0;

            var hasCustomised = false;

            angular.forEach(this.basket.package.products, function (product, foo, bar) {
                if (product.pivot.customisable == 1 && product.pivot.custom_quantity != product.pivot.original_quantity && product.pivot.custom_quantity > 0) {
                    hasCustomised = true;

                    ctrlr.basket.package.custom_price += (product.pivot.custom_quantity - product.pivot.original_quantity) * (product.weekly_pricing ? product.weekly_pricing[ctrlr.basket.rental_weeks.value - 1] : product.price);
                }

                if (product.type == 'box' && product.pivot.custom_quantity > 0) {
                    ctrlr.basket.package.box_count += product.pivot.custom_quantity;
                }
            });

            ctrlr.basket.package.added_cost = ctrlr.basket.package.custom_price - ctrlr.basket.package.weekly_pricing[this.basket.rental_weeks.value - 1];

            if (hasCustomised) {
                this.basket.package.customised = true;

                ctrlr.basket.package.discounted_to_next_package = false;

                var packagesList = [];

                angular.forEach(ctrlr.basket.package.type == 'residential' ? this.data.packages.residential : this.data.packages.commercial, function (pkg, foo, bar) {
                    if (pkg == ctrlr.basket.package)
                        return;

                    packagesList.push(pkg);
                });

                packagesList = orderBy(packagesList, 'box_count', false);

                var lowerBoxes = false;
                var higherBoxes = false;
                var stop = false;

                angular.forEach(packagesList, function (package, index, bar) {
                    if (stop)
                        return;

                    if (package.box_count < ctrlr.basket.package.box_count) {
                        lowerBoxes = package;
                    }

                    if (package.box_count >= ctrlr.basket.package.box_count) {
                        higherBoxes = package;
                        stop = true; // Must have found the other one too, if it exists.
                    }
                });

                if (lowerBoxes || higherBoxes) {

                    var priceCap = ctrlr.basket.package.custom_price;

                    var boxPrice = ctrlr.data.supplies.boxes[0].weekly_pricing[ctrlr.basket.rental_weeks.value - 1];

                    if (lowerBoxes) {
                        var tmpCount = ctrlr.basket.package.box_count - lowerBoxes.box_count;
                        ctrlr.basket.package.custom_price = lowerBoxes.weekly_pricing[ctrlr.basket.rental_weeks.value - 1] + (tmpCount * boxPrice); // Temporary number
                        ctrlr.basket.package.discounted_to_next_package = true;
                    }

                    if (higherBoxes && higherBoxes.weekly_pricing[ctrlr.basket.rental_weeks.value - 1] < ctrlr.basket.package.custom_price) {
                        ctrlr.basket.package.discounted_to_next_package = true;
                        ctrlr.basket.package.custom_price = higherBoxes.weekly_pricing[ctrlr.basket.rental_weeks.value - 1];
                    }
                }

                this.basket.package.custom_price += this.data.settings.customisation_fee;

            } else {
                this.basket.package.customised = false;
            }
        }

        this.modifyPackage = function (product, amount) {
            if (product.pivot.customisable == 1) {
                if (amount > 0 && product.pivot.custom_quantity < product.pivot.original_quantity + product.pivot.customisation_limit)
                    product.pivot.custom_quantity += 1;

                if (amount < 0 && product.pivot.custom_quantity > product.pivot.original_quantity)
                    product.pivot.custom_quantity--;

                this.recalculatePackage();
            }
        }

        this.resetPackage = function (package) {
            package.price = package.weekly_pricing[this.basket.rental_weeks.value - 1];
            package.customised = false;

            angular.forEach(package.products, function (product, foo, bar) {
                product.pivot.quantity = product.pivot.original_quantity;
            });
        }

        this.recalculate = function (recalcFromAccess) {
            if (!this.basket.package)
                return;

            this.recalculatePackage();

            this.basket.total = 0;
            this.basket.total += (this.basket.package.customised ? this.basket.package.custom_price : this.basket.package.weekly_pricing[this.basket.rental_weeks.value - 1]);

            var taxed = 0;

            this.basket.box_count = 0;

            angular.forEach(this.basket.package.products, function (product, key, obj) {
                if (product.type == 'box') {
                    ctrlr.basket.box_count += product.pivot.quantity;
                }
            });

            if (!recalcFromAccess)
                this.buildAccessOptions();

            angular.forEach(this.basket.moving_supplies, function (supply, key, obj) {
                ctrlr.basket.total += supply.weekly_pricing[ctrlr.basket.rental_weeks.value - 1] * supply.quantity;
            });

            angular.forEach(this.basket.packing_supplies, function (supply, key, obj) {
                ctrlr.basket.total += supply.price * supply.quantity;
                taxed += supply.price * supply.quantity;
            });

            this.basket.total += this.basket.dropoff.delivery_fee + this.basket.pickup.delivery_fee;
            //taxed += this.basket.dropoff.delivery_fee + this.basket.pickup.delivery_fee;

            this.basket.access_fee = 0;

            if (ctrlr.basket.dropoff.access.value == 'stairs') {
                this.basket.access_fee += this.data.settings.stairs_access_fee * ctrlr.basket.box_count;
            }

            if (ctrlr.basket.dropoff.access.value == 'elevator') {
                this.basket.access_fee += this.data.settings.elevator_access_fee * ctrlr.basket.box_count;
            }

            if (ctrlr.basket.pickup.access.value == 'stairs') {
                this.basket.access_fee += this.data.settings.stairs_access_fee * ctrlr.basket.box_count;
            }

            if (ctrlr.basket.pickup.access.value == 'elevator') {
                this.basket.access_fee += this.data.settings.elevator_access_fee * ctrlr.basket.box_count;
            }

            this.basket.total += this.basket.access_fee;
            //taxed += this.basket.access_fee;

            taxed *= this.data.settings.tax;

            this.basket.total += taxed;
            this.basket.tax = taxed;

            if (this.basket.discount > 0) {
                if (this.basket.total >= this.basket.discount_min) {
                    ctrlr.forms.personalDetails.discount.$setValidity('min-order', true);

                    if (this.basket.discount_type == 'percentage') {
                        this.basket.discount_value = Math.round(this.basket.total * (this.basket.discount / 100));
                        var newAmt = Math.round(this.basket.total - this.basket.discount_value);
                        this.basket.total = newAmt;
                    } else if (this.basket.discount_type == 'fixed') {
                        this.basket.total -= this.basket.discount;
                        this.basket.discount_value = this.basket.discount;
                    }
                } else {
                    ctrlr.forms.personalDetails.discount.$setValidity('min-order', false);
                }
            } else {
                ctrlr.forms.personalDetails.discount.$setValidity('min-order', true);
            }
        }

        this.modifyCart = function (product, adjustment) {
            if (product.rented && this.basket.moving_supplies.indexOf(product) < 0 && adjustment > 0) {
                product.quantity = 1;
                this.basket.moving_supplies.push(product);
            } else if (!product.rented && this.basket.packing_supplies.indexOf(product) < 0 && adjustment > 0) {
                product.quantity = 1;
                this.basket.packing_supplies.push(product);
            } else {
                product.quantity += adjustment;
            }

            this.recalculate();
        }

        this.directToStage = function (stage) {
            if (ctrlr.maxStage >= stage) {
                ctrlr.stage = stage;
            }
        }

        this.scrollToTop = function () {
            this.tempDisableScrollInterrupt();

            if (!this.isTouch)
                ctrlr.scrollContainer.scrollTo(0, 0, ctrlr.smoothScrollOptions.duration, ctrlr.smoothScrollOptions.easing);
            else
                ctrlr.bodyContainer.scrollTo(0, ctrlr.scrollSections.top.offset().top, ctrlr.smoothScrollOptions.duration, ctrlr.smoothScrollOptions.easing);
        }

        this.scrollToBottom = function () {
            this.tempDisableScrollInterrupt();

            if (!this.isTouch)
                ctrlr.scrollContainer.scrollTo(0, ctrlr.scrollContainer.height(), ctrlr.smoothScrollOptions.duration, ctrlr.smoothScrollOptions.easing);
            else
                ctrlr.bodyContainer.scrollTo(0, ctrlr.bodyContainer.height(), ctrlr.smoothScrollOptions.duration, ctrlr.smoothScrollOptions.easing);
        }

        this.tempDisableScrollInterrupt = function () {
            angular.module('zippgo', ['duScroll']).value('duScrollCancelOnEvents', false);

            $timeout(function () {
                ctrlr.enableScrollInterrupt();
            }, 100);
        }

        this.enableScrollInterrupt = function () {
            angular.module('zippgo', ['duScroll']).value('duScrollCancelOnEvents', 'scroll mousewheel touchmove');
        }

        this.proceedTo = {
            packages: function () {
                ctrlr.basketOpen = false;
                ctrlr.stage = 1;
                ctrlr.scrollToTop();
            },

            packingSupplies: function (isForward) {
                if (isForward && !ctrlr.basket.package)
                    return;

                ctrlr.basketOpen = false;

                /*if (ctrlr.stage < 2) {
                 ctrlr.stage = 2;
                 }*/

                ctrlr.stage = 2;

                if (ctrlr.maxStage < 2)
                    ctrlr.maxStage = 2;

                ctrlr.scrollToTop();
            },

            movingSupplies: function () {
                /*if (ctrlr.stage < 3) {
                 ctrlr.stage = 3;
                 }*/

                ctrlr.stage = 3;

                if (ctrlr.maxStage < 3)
                    ctrlr.maxStage = 3;

                ctrlr.basketOpen = false;

                ctrlr.scrollToTop();
            },

            dropOff: function () {
                /*if (ctrlr.stage < 4) {
                 ctrlr.stage = 4;
                 }*/

                ctrlr.stage = 4;

                if (ctrlr.maxStage < 4)
                    ctrlr.maxStage = 4;

                ctrlr.basketOpen = false;

                ctrlr.scrollToTop();
            },

            pickUp: function (isForward) {
                if (isForward && (ctrlr.forms.dropOff.$invalid || ctrlr.basket.dropoff.access.value == '' || ctrlr.dropOffNoZip || ctrlr.basket.dropoff.times.firstObj.id <= 0 || ctrlr.basket.dropoff.times.secondObj.id <= 0))
                    return;

                /*if (ctrlr.stage < 5) {
                 ctrlr.stage = 5;
                 }*/

                ctrlr.stage = 5;

                if (ctrlr.maxStage < 5)
                    ctrlr.maxStage = 5;

                ctrlr.basketOpen = false;

                ctrlr.scrollToTop();
            },

            personalDetails: function (isForward) {
                if (isForward && (ctrlr.forms.pickUp.$invalid || ctrlr.basket.pickup.access.value == '' || ctrlr.pickUpNoZip || ctrlr.basket.pickup.times.firstObj.id <= 0 || ctrlr.basket.pickup.times.secondObj.id <= 0))
                    return;

                /*if (ctrlr.stage < 6) {
                 ctrlr.stage = 6;
                 } */

                ctrlr.stage = 6;

                // CALL Social Proof popup script
                apiService.socialPopupHook();

                if (ctrlr.maxStage < 6)
                    ctrlr.maxStage = 6;

                ctrlr.basketOpen = false;

                ctrlr.scrollToTop();
            },

            paymentDetails: function (isForward) {
                if (isForward && (ctrlr.forms.personalDetails.$invalid || ctrlr.basket.personal.how_find.value == ''))
                    return;

                /*if (ctrlr.stage < 7) {
                 ctrlr.stage = 7;
                 }*/

                ctrlr.stage = 7;

                if (ctrlr.maxStage < 7)
                    ctrlr.maxStage = 7;

                ctrlr.basketOpen = false;

                ctrlr.submitOrder(false);

                ctrlr.scrollToTop();
            }
        }

        $scope.$watch(function () {
            return ctrlr.basket.slider_rental_weeks;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;

            if (!newVal)
                return;

            if (ctrlr.dropdowns.rental_weeks.length > 0)
                ctrlr.basket.rental_weeks = ctrlr.dropdowns.rental_weeks[newVal - 1];
        });

        $scope.$watch(function () {
            return ctrlr.basket.rental_weeks.value;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;
            ctrlr.basket.slider_rental_weeks = newVal;
            ctrlr.recalculate();
        });

        $scope.$watch(function () {
            return ctrlr.basket.dropoff.access.value;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;
            ctrlr.basket.dropoff.nativeAccess = newVal;
            ctrlr.recalculate(true);
        });

        $scope.$watch(function () {
            return ctrlr.basket.pickup.access.value;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;
            ctrlr.basket.pickup.nativeAccess = newVal;
            ctrlr.recalculate(true);
        });

        $scope.$watch(function () {
            return ctrlr.basket.dropoff.nativeAccess;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;

            var stop = false;
            angular.forEach(ctrlr.dropdowns.access.dropoff, function (access, foo, bar) {
                if (stop)
                    return;

                if (access.value == newVal) {
                    ctrlr.basket.dropoff.access = access;
                    stop = true;
                }
            });
        });

        $scope.$watch(function () {
            return ctrlr.basket.pickup.nativeAccess;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;

            var stop = false;
            angular.forEach(ctrlr.dropdowns.access.pickup, function (access, foo, bar) {
                if (stop)
                    return;

                if (access.value == newVal) {
                    ctrlr.basket.pickup.access = access;
                    stop = true;
                }
            });
        });

        $scope.$watch(function () {
            return ctrlr.basket.dropoff.times.firstNative;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;

            var stop = false;
            angular.forEach(ctrlr.time_slots.dropoff.first, function (slot, foo, bar) {
                if (stop)
                    return;

                if (slot.id == newVal) {
                    ctrlr.basket.dropoff.times.firstObj = slot;
                    stop = true;
                }
            });
        });

        $scope.$watch(function () {
            return ctrlr.basket.pickup.times.firstNative;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;

            var stop = false;
            angular.forEach(ctrlr.time_slots.pickup.first, function (slot, foo, bar) {
                if (stop)
                    return;

                if (slot.id == newVal) {
                    ctrlr.basket.pickup.times.firstObj = slot;
                    stop = true;
                }
            });
        });

        $scope.$watch(function () {
            return ctrlr.basket.dropoff.times.secondNative;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;

            var stop = false;
            angular.forEach(ctrlr.time_slots.dropoff.second, function (slot, foo, bar) {
                if (stop)
                    return;

                if (slot.id == newVal) {
                    ctrlr.basket.dropoff.times.secondObj = slot;
                    stop = true;
                }
            });
        });

        $scope.$watch(function () {
            return ctrlr.basket.pickup.times.secondNative;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;

            var stop = false;
            angular.forEach(ctrlr.time_slots.pickup.second, function (slot, foo, bar) {
                if (stop)
                    return;

                if (slot.id == newVal) {
                    ctrlr.basket.pickup.times.secondObj = slot;
                    stop = true;
                }
            });
        });

        $scope.$watch(function () {
            return ctrlr.basket.dropoff.times.firstObj.id;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;

            if (newVal > 0) {
                ctrlr.basket.dropoff.times.firstNative = newVal;
            }

            ctrlr.refreshTimeSlotDropdowns('dropoff');
        });

        $scope.$watch(function () {
            return ctrlr.basket.dropoff.times.secondObj.id;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;

            if (newVal > 0) {
                ctrlr.basket.dropoff.times.secondNative = newVal;
            }

            ctrlr.refreshTimeSlotDropdowns('dropoff');
        });

        $scope.$watch(function () {
            return ctrlr.basket.pickup.times.firstObj.id;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;

            if (newVal > 0) {
                ctrlr.basket.pickup.times.firstNative = newVal;
            }

            ctrlr.refreshTimeSlotDropdowns('pickup');
        });

        $scope.$watch(function () {
            return ctrlr.basket.pickup.times.secondObj.id;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;

            if (newVal > 0) {
                ctrlr.basket.pickup.times.secondNative = newVal;
            }

            ctrlr.refreshTimeSlotDropdowns('pickup');
        });


        $scope.$watch(function () {
            return ctrlr.basket.personal.how_find.value
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;
            ctrlr.basket.personal.howFindNative = newVal;
        });

        $scope.$watch(function () {
            return ctrlr.basket.personal.howFindNative;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;

            var stop = false;
            angular.forEach(ctrlr.dropdowns.how_find, function (how, foo, bar) {
                if (stop)
                    return;

                if (how.value == newVal) {
                    ctrlr.basket.personal.how_find = how;
                    stop = true;
                }
            });
        });


        $scope.$watch(function () {
            return ctrlr.basket.payment.expiration.month.value
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;
            ctrlr.basket.payment.expiration.monthNative = newVal;
        });

        $scope.$watch(function () {
            return ctrlr.basket.payment.expiration.monthNative;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;

            var stop = false;
            angular.forEach(ctrlr.dropdowns.months, function (month, foo, bar) {
                if (stop)
                    return;

                if (month.value == newVal) {
                    ctrlr.basket.payment.expiration.month = month;
                    stop = true;
                }
            });
        });

        $scope.$watch(function () {
            return ctrlr.basket.payment.expiration.year.value
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;
            ctrlr.basket.payment.expiration.yearNative = newVal;
        });

        $scope.$watch(function () {
            return ctrlr.basket.payment.expiration.yearNative;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;

            var stop = false;
            angular.forEach(ctrlr.dropdowns.years, function (year, foo, bar) {
                if (stop)
                    return;

                if (year.value == newVal) {
                    ctrlr.basket.payment.expiration.year = year;
                    stop = true;
                }
            });
        });

        /*$scope.$watch(function() {
         return ctrlr.basket.dropoff.times.first
         }, function(newVal, oldVal) {
         if (newVal == oldVal) return;
         ctrlr.basket.dropoff.times.firstObj = ctrlr.time_slots.dropoff[newVal];
         });

         $scope.$watch(function() {
         return ctrlr.basket.dropoff.times.second
         }, function(newVal, oldVal) {
         if (newVal == oldVal) return;
         ctrlr.basket.dropoff.times.secondObj = ctrlr.time_slots.dropoff[newVal];
         });

         $scope.$watch(function() {
         return ctrlr.basket.pickup.times.first
         }, function(newVal, oldVal) {
         if (newVal == oldVal) return;
         ctrlr.basket.pickup.times.firstObj = ctrlr.time_slots.pickup[newVal];
         });

         $scope.$watch(function() {
         return ctrlr.basket.pickup.times.second
         }, function(newVal, oldVal) {
         if (newVal == oldVal) return;
         ctrlr.basket.pickup.times.secondObj = ctrlr.time_slots.pickup[newVal];
         });

         $scope.$watch(function() {
         return ctrlr.basket.dropoff.times.firstObj
         }, function(newVal, oldVal) {
         if (newVal == oldVal) return;
         ctrlr.basket.dropoff.times.first = newVal.id;
         });

         $scope.$watch(function() {
         return ctrlr.basket.dropoff.times.secondObj
         }, function(newVal, oldVal) {
         if (newVal == oldVal) return;
         ctrlr.basket.dropoff.times.second = newVal.id;
         });

         $scope.$watch(function() {
         return ctrlr.basket.pickup.times.firstObj
         }, function(newVal, oldVal) {
         if (newVal == oldVal) return;
         ctrlr.basket.pickup.times.first = newVal.id;
         });

         $scope.$watch(function() {
         return ctrlr.basket.pickup.times.second
         }, function(newVal, oldVal) {
         if (newVal == oldVal) return;
         ctrlr.basket.pickup.times.second = newVal.id;
         ctrlr.forms.pickUp
         });*/

        $scope.$watch(function () {
            if (ctrlr.basket.dropoff.address)
                return ctrlr.basket.dropoff.address || '';
            else
                return '';
        }, function (newVal, oldVal) {
            if (newVal.formatted_address == oldVal.formatted_address) {
                newVal.zipcode = oldVal.zipcode;
            }

            if (newVal.zipcode !== undefined && newVal.zipcode !== "") {
                return;
            }

            ctrlr.uncheckedDropOff = true;
            ctrlr.validateDropOff();
        });

        $scope.$watch(function () {
            if (ctrlr.basket.pickup.address)
                return ctrlr.basket.pickup.address || '';
            else
                return '';
        }, function (newVal, oldVal) {

            if (newVal.formatted_address == oldVal.formatted_address) {
                newVal.zipcode = oldVal.zipcode;
            }

            if (newVal.zipcode !== undefined && newVal.zipcode !== "") {
                return;
            }

            ctrlr.uncheckedPickup = true;
            ctrlr.validatePickup();
        });

        $scope.$watch(function () {
            return ctrlr.basket.payment.expiration.month;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;
            ctrlr.checkExpiration(newVal, ctrlr.basket.payment.expiration.year);
        });

        $scope.$watch(function () {
            return ctrlr.basket.payment.expiration.year;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;
            ctrlr.checkExpiration(ctrlr.basket.payment.expiration.month, newVal);
        });

        $scope.$watch(function () {
            return ctrlr.basket.package;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;
            if (oldVal.customised) {
                console.log('asdasd');
                ctrlr.resetPackage(oldVal);
            }
        });

        $scope.$watch(function () {
            return ctrlr.basket.personal.company;
        }, function (newVal, oldVal) {
            if (newVal == oldVal) return;
            ctrlr.checkCompanyValidity(newVal);
        });

        this.checkCompanyValidity = function (name) {
            if (!ctrlr.data || !ctrlr.data.settings.commercial)
                return;

            if (name != '' && ctrlr.basket.package.type != 'commercial')
                ctrlr.forms.personalDetails.company.$setValidity('commercial-package', false);
            else
                ctrlr.forms.personalDetails.company.$setValidity('commercial-package', true);
        }

        this.checkExpiration = function (month, year) {
            if (year > 0 && month > -1) {
                var date = new Date(year, month + 1, 0); // +1 to the month to get last moment of previous month with 0 day.
                var now = new Date();
                if (date > now) {
                    ctrlr.forms.paymentDetails.cardMonth.$setValidity('card-expiration', true);
                    ctrlr.forms.paymentDetails.cardYear.$setValidity('card-expiration', true);
                    return; // This is fine.
                } else {
                    ctrlr.forms.paymentDetails.cardMonth.$setValidity('card-expiration', false);
                    ctrlr.forms.paymentDetails.cardYear.$setValidity('card-expiration', false);
                    return;
                }
            }
        }

        this.validateDropOff = function () {
            if (!this.basket.dropoff.address)
                return;
            this.uncheckedDropOff = true;

            // This is partially to simulate a better user experience, and partially to avoid overworking the server if the user changes it again.
            if (this.checkDropOffTimeout)
                $timeout.cancel(this.checkDropOffTimeout);

            ctrlr.basket.dropoff.address.zipcode = '';
            var zipcode = ctrlr.getZipcodeFromAddress(ctrlr.basket.dropoff.address.address_components);
            if (!zipcode) {
                ctrlr.dropOffNoZip = true;
            } else {
                ctrlr.dropOffNoZip = false;

                this.checkingDropOff = true;

                this.checkDropOffTimeout = $timeout(function () {
                    apiService.checkZipCode(zipcode).then(function (response) {
                        ctrlr.checkingDropOff = false;
                        ctrlr.uncheckedDropOff = false;
                        ctrlr.basket.dropoff.delivery_fee = response.data.delivery_fee;
                        ctrlr.basket.dropoff.address.zipcode = zipcode;
                        if (response.data.delivery_fee >= 0) {
                            if (ctrlr.regions.dropoff.id != response.data.id) {
                                ctrlr.regions.dropoff = response.data;
                                console.log("DETECTED REGION CHANGE");
                                ctrlr.checkPackageInRegions();
                                ctrlr.getTimeSlots();
                            }
                        }

                    }, function (error) {
                        ctrlr.checkingDropOff = false;
                        alert(error);
                    });
                }, 500);
            }

        }

        this.validatePickup = function () {
            if (!this.basket.pickup.address)
                return;

            this.uncheckedPickup = true;

            // This is partially to simulate a better user experience, and partially to avoid overworking the server if the user changes it again.
            if (this.checkPickUpTimeout)
                $timeout.cancel(this.checkPickUpTimeout);

            ctrlr.basket.pickup.address.zipcode = '';
            var zipcode = ctrlr.getZipcodeFromAddress(ctrlr.basket.pickup.address.address_components);
            if (!zipcode) {
                ctrlr.pickUpNoZip = true;
            } else {
                ctrlr.pickUpNoZip = false;

                this.checkingPickUp = true;


                this.checkPickUpTimeout = $timeout(function () {
                    apiService.checkZipCode(zipcode).then(function (response) {
                        ctrlr.checkingPickUp = false;
                        ctrlr.uncheckedPickup = false;
                        ctrlr.basket.pickup.delivery_fee = response.data.delivery_fee;
                        ctrlr.basket.pickup.address.zipcode = zipcode;

                        if (response.data.delivery_fee >= 0) {
                            if (ctrlr.regions.pickup.id != response.data.id) {
                                ctrlr.regions.pickup = response.data;
                                console.log("DETECTED REGION CHANGE");
                                ctrlr.checkPackageInRegions();
                                ctrlr.getTimeSlots(false, false, true);
                            }
                        }
                    }, function (error) {
                        ctrlr.checkingPickUp = false;
                        alert(error);
                    });
                }, 500);
            }
        }

        this.checkPackageInRegions = function () {
            var packageInDropOffRegion = false;
            var packageInPickUpRegion = false;

            angular.forEach(ctrlr.regions.dropoff.packages, function (package, index, full) {
                if (ctrlr.basket.package.id == package.id)
                    packageInDropOffRegion = true;
            });

            angular.forEach(ctrlr.regions.pickup.packages, function (package, index, full) {
                if (ctrlr.basket.package.id == package.id)
                    packageInPickUpRegion = true;
            });

            if (packageInDropOffRegion && packageInPickUpRegion) {
                ctrlr.packageValidForRegion = true;
            } else {
                ctrlr.packageValidForRegion = false;
                ctrlr.getData();
            }
        }

        this.getZipcodeFromAddress = function (addrObject) {
            var found = false;

            angular.forEach(addrObject, function (component, foo, bar) {
                if (found)
                    return;

                if (component.types.length > 0 && component.types[0] == 'postal_code')
                    found = component.long_name;
            });

            return found;
        }

        this.checkDropOffAddressInput = function () {
            $timeout(function () {
                if (!ctrlr.basket.dropoff.address) {
                    ctrlr.forms.dropOff.addressInput.$setValidity('incomplete-address', false);
                } else {
                    ctrlr.forms.dropOff.addressInput.$setValidity('incomplete-address', true);
                }
            }, 100);
        }

        this.checkPickUpAddressInput = function () {
            $timeout(function () {
                if (!ctrlr.basket.pickup.address) {
                    ctrlr.forms.pickUp.addressInput.$setValidity('incomplete-address', false);
                } else {
                    ctrlr.forms.pickUp.addressInput.$setValidity('incomplete-address', true);
                }
            }, 100);
        }

        this.checkDiscountCode = function () {
            if (this.basket.discount_code == '') {
                this.basket.discount_type = false;
                this.basket.discount = 0;
                this.forms.personalDetails.discount.$setValidity('invalid-code', true);
                this.recalculate();
            } else {

                this.checkingDiscountCode = true;

                if (this.checkDiscountCodeTimeout)
                    $timeout.cancel(this.checkDiscountCodeTimeout);

                ctrlr.basket.discount_type = false;

                this.checkDiscountCodeTimeout = $timeout(function () {
                    apiService.checkDiscountCode(ctrlr.basket.discount_code).then(function (response) {
                        ctrlr.checkingDiscountCode = false;
                        ctrlr.basket.discount = response.data.value;
                        ctrlr.basket.discount_type = response.data.type;
                        ctrlr.basket.discount_min = response.data.minimum_order;
                        ctrlr.forms.personalDetails.discount.$setValidity('invalid-code', true);
                        ctrlr.recalculate();
                    }, function (error) {
                        ctrlr.checkingDiscountCode = false;
                        ctrlr.basket.discount = 0;
                        ctrlr.basket.discount_type = 'invalid';
                        ctrlr.forms.personalDetails.discount.$setValidity('invalid-code', false);
                        ctrlr.recalculate();
                    });
                }, 500);
            }
        }

        this.submitOrder = function (isFinal) {
            var allValid = true;

            if (isFinal) {
                if (ctrlr.forms.paymentDetails.$invalid || !ctrlr.isExpirationValid(true))
                    return;

                angular.forEach(ctrlr.forms, function (form, index, full) {
                    if (form.$invalid)
                        allValid = false;
                });

                ctrlr.basket.payment.cardType = ctrlr.forms.paymentDetails.cardNumber.$ccType;

                ctrlr.submissionErrors = false;
                ctrlr.submittingOrder = true;
            }

            if (!allValid) {
                alert("You have invalid fields");
                ctrlr.submittingOrder = false;
            } else {
                apiService.saveOrder(ctrlr.basket, isFinal).then(function (response) {
                    //alert("This is the calculated total: $" + (response.data.order_total / 100))
                    if (isFinal) {
                        $window.location.href = '/thank-you/' + response.data.token;
                    } else {
                        ctrlr.submittingOrder = false;
                        ctrlr.basket.order_token = response.data.token;
                    }
                }, function (response) {

                    ctrlr.submittingOrder = false;
                    if (isFinal) {
                        if (response.status == 422) {
                            ctrlr.submissionErrors = response.data;
                        } else {
                            if (response.data.error) {
                                swal({
                                    title: response.data.message.title,
                                    text: response.data.message.text,
                                    type: 'error',
                                    showCancelButton: false,
                                    confirmButtonText: 'OK',
                                });
                            } else {
                                swal({
                                    title: "An unknown error occurred",
                                    text: "Please try again, or contact us to finalize your order",
                                    type: 'error',
                                    showCancelButton: false,
                                    confirmButtonText: 'OK',
                                });
                            }
                        }
                    }

                    console.log(response.data);
                });
            }
        }

        this.productNotZero = function (item) {
            return item.pivot.quantity > 0;
        }

        // Giving the controller time to finish init.
        $timeout(function () {
            ctrlr.getData();
        }, 100);

        this.stage = 1;
        this.maxStage = 1;
    }]);
