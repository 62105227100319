angular.module('zippgo.controllers').controller('CheckController', ['ApiService', '$window', function (apiService, $window) {
    var ctrlr = this;

    this.checkingRegions = false;

    this.hiddenForm = false;

    this.triedSubmit = false;

    this.checkRegions = function () {
        ctrlr.triedSubmit = true;

        if (ctrlr.visibleForm.$invalid)
            return;

        ctrlr.checkingRegions = true;

        apiService.checkRegions(this.from, this.to).then(function (response) {
			$("#hiddenForm").submit();
        }, function (err) {
            ctrlr.checkingRegions = false;
            if (err.status == 501) {
                swal({
                    title: "Sorry...",
                    text: "Sorry! At least one of your zip codes is outside of our free service area. Contact us for potential availability and fees.",
                    type: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'OK'
                });
            } else {
                ctrlr.checkingRegions = false;

                swal({
                    title: "Oops!",
                    text: "Something went wrong while trying to check these zipcodes. Please try again.",
                    type: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'OK'
                });
            }
        });
    }
}]);