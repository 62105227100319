angular.module('zippgo', [
    'ngRoute',
    'ngAnimate',
    //'rzModule',
    //'angulartics',
    //'angulartics.google.analytics',
    //'infinite-scroll',
    'relativeDate',
    'zippgo.controllers',
    'zippgo.services',
    'zippgo.directives',
    'zippgo.filters',
    'zippgo.factories',
    'google.places',
    'credit-cards',
    //'smoothScroll',
    'duScroll',
    'ui.mask',
    'ngDropdowns',
    '720kb.datepicker',
    'rzModule',
    'ui.utils.masks',
    //'mm.foundation',
    'oitozero.ngSweetAlert'
]).
config(['$routeProvider', '$locationProvider', function($routeProvider, $locationProvider) {

}]).run(['$rootScope', function($rootScope) {
    $rootScope.$on('$routeChangeSuccess', function(ev, data) {
        if (data.$$route && data.$$route.controller)
            $rootScope.controllerName = data.$$route.controller;
    });
}]).
config(['uiMask.ConfigProvider', function(uiMaskConfigProvider) {
    //uiMaskConfigProvider.maskDefinitions({'A': /[a-z]/, '*': /[a-zA-Z0-9]/});
    uiMaskConfigProvider.clearOnBlur(false);
    uiMaskConfigProvider.eventsToHandle(['input', 'keyup', 'click']);
}]).
config(['$httpProvider', function($httpProvider) {
    $httpProvider.defaults.withCredentials = true;
    $httpProvider.interceptors.push('responseObserver');
    $httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

}]);

angular.module('zippgo.controllers', []);
angular.module('zippgo.services', []);
angular.module('zippgo.directives', []);
angular.module('zippgo.filters', []);
angular.module('zippgo.factories', []);