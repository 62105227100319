angular.module('zippgo.factories').factory('responseObserver', ['$q', '$window', function responseObserver($q, $window) {
    return {
        'responseError': function(errorResponse) {
            switch (errorResponse.status) {
                /*case 401:
                    $window.location = '/login/steam';
                    break;*/
            }
            return $q.reject(errorResponse);
        }
    };
}]);