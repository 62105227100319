angular.module('zippgo.filters').filter('isoDate', function() {
	return function(badTime) {
		if (badTime == undefined)
			return;

		if (badTime.date)
			badTime = badTime.date;

		var goodTime = badTime.replace(/(.+) (.+)/, "$1T$2Z");
		return goodTime;
	};
});