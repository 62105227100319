angular.module('zippgo.services').service('ApiService', ['$http', function($http) {
    var baseUrl = "/api";

    this.getProductData = function(dropoff, pickup) {
        return $http.get('/api/populate', {
            params: {
                'dropoff': dropoff,
                'pickup': pickup
            }
        });
    }

    this.checkZipCode = function(zipcode) {
        return $http.get('/api/zipcode', {
            params: {
                'zipcode': zipcode
            }
        });
    }

    this.getTimeSlots = function(dropoffRegion, pickupRegion, date, weeks) {        
        return $http.get('/api/slots', {
            params: {
                'dropoff_date': date,
                'dropoff_region': dropoffRegion,
                'pickup_region': pickupRegion,
                weeks: weeks,
            }
        });
    }

    this.checkDiscountCode = function(code) {
        return $http.get('/api/discount', {
            params: {
                code: code
            }
        });
    }

    this.saveOrder = function(order, isSubmission) {
        return $http.post('/api/submit', {
            order: order,
            submit: isSubmission
        });
    }


    this.submitQuote = function(name, email, phone, location, date) {
        return $http.post('/api/submitQuote', {
            name: name,
            email: email,
            phone: phone,
            location: location,
            date: date
        });
    }

    this.checkRegions = function(from, to) {
        return $http.post('/api/checkRegions', {
            from: from,
            to: to
        });
    }

    this.socialPopupHook = function () {
        $http.post('https://api.provesrc.com/webhooks/track/85182c72ed8d9d835531f6904a51297c7b1b7f454cc50c735e0a54b8b60fbc0a', {});
    }
}]);